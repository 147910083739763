/* eslint-disable react/prop-types */
/* eslint-disable no-use-before-define */
/* eslint-disable react/no-danger */
/* eslint-disable import/no-unresolved */
import React, { useEffect, useState } from "react";
import { Card, Tabs, Typography } from "antd";
import CodeEditor from "rmce";
import { downloadWidget } from "api";
import { marked } from "marked";

function WidgetSection({ title, description, apiUrl, documentId, snippets }) {
  const { Title } = Typography;

  const [widgetCode, setWidgetCode] = useState();
  const [codeTabs, setCodeTabs] = useState();

  useEffect(() => {
    if (snippets) {
      processSnippets(snippets);
    }
    if (documentId) {
      processWidget();
    }
  }, [title, description, apiUrl, documentId, snippets]);

  // eslint-disable-next-line no-shadow
  const processSnippets = async snippets => {
    const objs = [];
    // eslint-disable-next-line no-restricted-syntax
    for (const snippet of snippets) {
      objs.push(
        <Tabs.TabPane key={snippet.title} tab={<span>{snippet.title}</span>}>
          <CodeEditor
            width="100%"
            style={{ height: 500 }}
            language={snippet.language}
            className="rmce"
            value={snippet.value}
            readOnly
          />
        </Tabs.TabPane>,
      );
    }

    setCodeTabs(objs);
  };

  const processWidget = async () => {
    const token = window.localStorage.getItem("token");

    const res = await downloadWidget(documentId);
    const html = (await res.text()).replace("<API_URL>", apiUrl).replace("<API_TOKEN>", token);
    setWidgetCode(html);
  };

  return (
    <>
      <Title level={2}>{title}</Title>
      <div dangerouslySetInnerHTML={{ __html: marked(description) }} />
      {widgetCode && (
        <Card>
          <iframe
            frameBorder={0}
            srcDoc={widgetCode}
            allow="microphone; camera"
            title={title}
            width="100%"
            height="470"
          />
        </Card>
      )}
      <br />
      <Tabs defaultActiveKey="html" tabPosition="left">
        {codeTabs}
      </Tabs>
    </>
  );
}

export { WidgetSection };
